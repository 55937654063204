<template>
	<v-card
		elevation="0"
		class="ma-0 pa-0"
		:loading="loadingData"
	>
		<v-simple-table :height="userFormHeight">
			<template v-slot:default>
				<v-row dense no-gutters class="pt-5 mr-3">
					<v-col cols="12" sm="12" md="6" lg="6" xl="6">
						<v-card
							class="ma-2 mt-5 mb-5"
						>
							<v-sheet
								class="v-sheet--offset mx-auto"
								color="indigo"
								elevation="12"
								max-width="calc(100% - 32px)"
							>
								<v-sparkline
									auto-draw
									:auto-draw-duration="autoDrawDuration"
									:labels="register_labels"
									show-labels
									:value="register_values"
									color="white"
									line-width="2"
									padding="16"
								></v-sparkline>
							</v-sheet>

							<v-card-text class="pt-0">
								<div class="title font-weight-light mb-2">
									Applicant Registrations
								</div>
								<v-divider class="my-2"></v-divider>
								<v-icon
									class="mr-2"
									small
								>
									mdi-clock
								</v-icon>
								<span v-if="!loadingData" class="caption grey--text font-weight-light">last registration {{ last_registered }} ago</span>
							</v-card-text>
						</v-card>
					</v-col>
					
					<v-col cols="12" sm="12" md="6" lg="6" xl="6">
						<v-card
							class="ma-2 mt-5 mb-5"
						>
							<v-sheet
								class="v-sheet--offset mx-auto"
								color="purple"
								elevation="12"
								max-width="calc(100% - 32px)"
							>
								<v-sparkline
									auto-draw
									:auto-draw-duration="autoDrawDuration"
									:labels="visitor_labels"
									:value="visitor_values"
									color="white"
									line-width="2"
									padding="16"
								></v-sparkline>
							</v-sheet>

							<v-card-text class="pt-0">
								<div class="title font-weight-light mb-2">
									Applicant Visitors
								</div>
								<v-divider class="my-2"></v-divider>
								<v-icon
									class="mr-2"
									small
								>
									mdi-clock
								</v-icon>
								<span v-if="!loadingData" class="caption grey--text font-weight-light">last visitor {{ last_visitor }} ago</span>
							</v-card-text>
						</v-card>
					</v-col>
					
					<v-col cols="12" sm="12" md="6" lg="6" xl="6">
						<v-card
							class="ma-2 mt-5 mb-5"
						>
							<v-sheet
								class="v-sheet--offset mx-auto"
								color="green"
								elevation="12"
								max-width="calc(100% - 32px)"
							>
								<v-sparkline
									auto-draw
									:auto-draw-duration="autoDrawDuration"
									:labels="verified_labels"
									:value="verified_values"
									color="white"
									line-width="2"
									padding="16"
								></v-sparkline>
							</v-sheet>

							<v-card-text class="pt-0">
								<div class="title font-weight-light mb-2">
									Applicant Verified
								</div>
								<v-divider class="my-2"></v-divider>
								<v-icon
									class="mr-2"
									small
								>
									mdi-clock
								</v-icon>
								<span v-if="!loadingData" class="caption grey--text font-weight-light">last verified {{ last_verified }} ago</span>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</template>
		</v-simple-table>
	</v-card>
</template>

<script>

/*
Dashboard
Visitors
New Applicant
*/

import { mapGetters } from 'vuex';

export default {
	data: () => ({
		loadingData		: false,
		dates			: [],
		autoDrawDuration: 1500,
		
		last_registered	: '',
		last_visitor	: '',
		last_verified	: '',
		
		register_labels	: [],
		register_values	: [],
		visitor_labels	: [],
		visitor_values	: [],
		verified_labels	: [],
		verified_values	: []
	}),
	methods: {
		getDashboard(){
			this.loadingData = true;
			
			this.$http.get('dashboard/admin')
			.then((resp) => {
				var i = 0;
				
				this.last_registered = resp.data.last_register;
				
				resp.data.dates.forEach((item) => {
					i = 0;
					if( typeof resp.data.registered.find(({ days }) => days === item.date) !== 'undefined'){
						i = resp.data.registered.find(({ days }) => days === item.date).count;
					}
					this.register_values.push(i);
					this.register_labels.push(item.days);
				});
				
				this.last_visitor = resp.data.last_visitor;
				
				resp.data.dates.forEach((item) => {
					i = 0;
					if( typeof resp.data.visitors.find(({ days }) => days === item.date) !== 'undefined'){
						i = resp.data.visitors.find(({ days }) => days === item.date).count;
					}
					this.visitor_values.push(i);
					this.visitor_labels.push(item.days);
				});
				
				this.last_verified = resp.data.last_verified;
				
				resp.data.dates.forEach((item) => {
					i = 0;
					if( typeof resp.data.verified.find(({ days }) => days === item.date) !== 'undefined'){
						i = resp.data.verified.find(({ days }) => days === item.date).count;
					}
					this.verified_values.push(i);
					this.verified_labels.push(item.days);
				});
			})
			.finally(() => {
				this.loadingData = false;
			});
		},
	},
	computed: {
		...mapGetters(['userFormHeight']),
	},
	created () {
		this.getDashboard();
	},
}
</script>

<style scoped>

	>>> .v-sheet--offset {
		top: -24px;
		position: relative;
	}
	
</style>