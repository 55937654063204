<template>
	<v-card
		elevation="0"
		:loading="loadingData"
		class="ma-0 pa-0"
	>
		<v-simple-table :height="userFormHeight">
			<template v-slot:default>
				<v-row dense no-gutters class="pt-10 mr-3">
					<v-col
						v-for="item in documentSummary"
						:key="item.key"
						cols="12" sm="4" md="4" lg="4" xl="4"
						class="mb-6 px-1"
					>
						<v-card outlined>
							<v-list-item :color="item.color">
								<v-list-item-avatar
									tile
									:size="$vuetify.breakpoint.mdAndUp ? 60 : 45"
									:color="item.color"
									style="position:absolute;"
									class="rounded-lg mb-8 mt-n14 mt-sm-n14 mt-md-n16 mt-lg-n16 mt-xl-n16"
								>
									<v-icon :large="$vuetify.breakpoint.mdAndUp" dark>{{ item.icon }}</v-icon>
								</v-list-item-avatar>
								
								<v-list-item-content class="text-right pb-1">
									<v-list-item-subtitle class="text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1" :class="[item.textColor]">{{ item.title }}</v-list-item-subtitle>
									<v-list-item-title class="text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 mb-1 font-weight-bold" :class="[item.textColor]">{{ item.count }}</v-list-item-title>
									<v-divider></v-divider>
									<v-list-item-subtitle class="text--disabled text-left py-1">
										<v-icon small>mdi-file-eye</v-icon>
										<font
											style="cursor:pointer"
											class="text-caption text-sm-caption text-md-body-2 text-lg-body-2 text-xl-body-2"
											@click="showDocuments(item.key, item.title)"
										>
											View Documents
											<v-progress-circular
												v-if="showDocumentLoading == item.title"
												indeterminate
												color="primary"
												size="12"
												width="2"
												class="ml-1"
											></v-progress-circular>
										</font>
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-card>
					</v-col>
				</v-row>
				
				<v-row dense no-gutters class="mr-3">
					<v-col cols="12" sm="12" md="6" lg="4" xl="4" class="px-1">
						<v-card outlined class="mb-5">
							<v-card-title>
								APPLICATION<v-spacer></v-spacer>
								
								<v-tooltip bottom v-if="isLoaded">
									<template v-slot:activator="{ on: onToolTip }">
										<v-btn v-on="onToolTip" @click="dialogApplication = true" icon color="info" style="float:right;" id="v-dashboard-step-1">
											<v-icon>mdi-pencil-circle</v-icon>
										</v-btn>
									</template>
									<small>Edit</small>
								</v-tooltip>
								
							</v-card-title>
							
							<v-card-text>
								<v-row
									no-gutters
									v-if="application.length == 0"
								>
									<v-col cols="12" class=" text-body-1 mb-6">No record found</v-col>
								</v-row>
								
								<v-row no-gutters dense v-else v-for="item in application" :key="item.caption">
									<v-col cols="6">{{ item.caption }}</v-col>
									
									<v-col cols="6" v-if="item.type == 'text'">{{ item.value }}</v-col>
									
									<v-col cols="5" v-if="item.type == 'progress'">
										<v-progress-linear
											:value="item.value"
											striped
											color="blue darken-1"
											height="20"
											class="mb-1 rounded-lg font-weight-light"
										>
											<strong class="pr-2">{{ item.value }}</strong>
										</v-progress-linear>
									</v-col>
									<v-col cols="1" v-if="item.type == 'progress'">
										<v-tooltip bottom nudge-bottom="-10">
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon v-on="onToolTip" @click="dialogLevelProfile = true;" color="info" style="float:right; cursor:pointer;">{{ item.icon }}</v-icon>
											</template>
											<small>View</small>
										</v-tooltip>
									</v-col>
									
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
					
					<v-col cols="12" sm="12" md="6" lg="4" xl="4" class="px-1">
						<v-card outlined class="mb-5">
							<v-card-title>EMPLOYMENT</v-card-title>
							
							<v-card-text>
								<v-row
									no-gutters
									v-if="employment.length == 0"
								>
									<v-col cols="12" class=" text-body-1 mb-6">No record found</v-col>
								</v-row>
								
								<v-row no-gutters dense v-else v-for="item in employment" :key="item.caption">
									<v-col cols="6">{{ item.caption }}</v-col>
									<v-col cols="6">{{ item.value }}</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
					
					<v-col cols="12" sm="12" md="6" lg="4" xl="4" class="px-1">
						<v-card outlined class="mb-5">
							<v-card-title>
								SCHEDULE
							</v-card-title>
							
							<v-card-text>
								<v-row
									no-gutters
									v-if="schedule.length == 0"
								>
									<v-col cols="12" class=" text-body-1 mb-6">No upcoming schedule</v-col>
								</v-row>
								
								<v-row
									no-gutters
									v-else
									v-for="item in schedule"
									:key="item.caption"
								>
									<v-col cols="6">{{ item.caption }}</v-col>
									<v-col cols="6">: {{ item.value }}</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
					
				</v-row>
				
			</template>
		</v-simple-table>
			
		<v-dialog
			v-model="dialogDocument"
			scrollable
			max-width="650"
			:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<v-card>
				<v-card-title class="headline primary white--text" primary-title>
					{{ dialogTitle }} Documents
					
					<v-spacer></v-spacer>
					
					<v-tooltip bottom>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn icon
								v-on="tooltip"
								@click="dialogDocument = false"
								class="white--text"
							>
								<v-icon small dark>mdi-close</v-icon>
							</v-btn>
						</template>
						<span>Close</span>
					</v-tooltip>
				</v-card-title>
				
				<v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">Document Name</th>
									<th class="text-center">Date Issued</th>
									<th class="text-center">Date Valid</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in documents" :key="item.doc_name">
									<td class="text-left">{{ item.doc_name }}</td>
									<td class="text-center">{{ item.date_issued }}</td>
									<td class="text-center">{{ item.date_valid }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-dialog>
		
		<v-dialog
			v-model="dialogApplication"
			scrollable
			:max-width="loadingApplication? 355 : 650"
			:persistent="loadingApplication"
			:fullscreen="$vuetify.breakpoint.xsOnly && !loadingApplication"
			@keydown.enter="saveApplication"
		>
		
			<v-card
				color="primary"
				dark
				v-if="loadingApplication"
			>
				<v-card-text class="pt-2">
					Saving...
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
				</v-card-text>
			</v-card>
				
			<v-card v-else>
				<v-card-title class="headline primary white--text" primary-title>
					Edit Application
					
					<v-spacer></v-spacer>
						
					<v-btn icon
						@click="dialogApplication = false"
						class="white--text"
					>
						<v-icon small dark>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				
				<v-card-text class="mt-2">
					<v-form ref="formApplication">
						<v-row no-gutters dense>
							<v-alert
								dense
								outlined
								border="left"
								:value="errorOn && dialogApplication"
								:type="errorType"
								class="ma-0 py-1"
							>
								{{ errorMessage }}
							</v-alert>
							
							<v-col cols="12">
								<v-select
									v-model="applicationStatus"
									label="Status"
									:items="statusOption"
									item-text="STATDESC"
									item-value="STATCODE"
									:rules= "rulesStatus"
									validate-on-blur
								></v-select>
							</v-col>
							
							<v-col cols="12">
								<v-select
									v-model="applicationPosition"
									label="Position"
									:items="rankOption"
									item-text="RANKDESC"
									item-value="RANKID"
									:rules= "rulesPosition"
									validate-on-blur
								></v-select>
							</v-col>
							
							<v-col cols="12">
								<v-select
									v-model="applicationAltPosition"
									label="Alternate Position"
									:items="rankOption"
									item-text="RANKDESC"
									item-value="RANKID"
									:rules= "rulesAltPosition"
									validate-on-blur
								></v-select>
							</v-col>
							
							<v-col cols="12">
								<v-select
									v-model="vesselPreferred"
									dense
									flat
									:items="vesselTypeOption"
									label="Preferred Vessel Type"
									multiple
									:rules= "rulesVesselType"
									validate-on-blur
									class="mt-2 mb-n4"
								>
									<template v-slot:selection="{ item, index }">
										<v-chip small v-if="index === 0">
										<span>{{ item }}</span>
										</v-chip>
										<span
										v-if="index === 1"
										class="grey--text caption"
										>(+{{ vesselPreferred.length - 1 }} others)</span>
									</template>
								</v-select>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				
				<v-card-actions>
					<small class="pl-4 font-weight-light">Note: All field is required</small>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="dialogApplication = false; startGuide()">Cancel</v-btn>
					<v-btn color="blue darken-1" text @click="saveApplication">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<v-dialog
			v-model="dialogLevelProfile"
			scrollable
			max-width="650"
		>
			<v-card>
				<v-card-title class="headline primary white--text" primary-title>
					Coming Soon
					<v-spacer></v-spacer>
						
					<v-btn icon
						@click="dialogLevelProfile = false"
						class="white--text"
					>
						<v-icon small dark>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
			</v-card>
		</v-dialog>
		
		<v-tour name="vTourDashboard" :steps="vTourSteps" :options="vTourOptions" :callbacks="vTourCallbacks"></v-tour>
	</v-card>
</template>

<script>

import { STORE_INFO } from '@/store/actions/user';

import { mapGetters } from 'vuex';

var timeOutTour = null;
var timeoutData	= null;
var timeoutLoading = null;
var timeoutSaveApplication = null;
export default {
	data () {
		return {
			dialogLevelProfile		: false,
			
			profileSummary			: false,
			applicant				: false,
			dialogDocument			: false,
			dialogApplication		: false,
			dialogTitle				: '',
			
			applicationStatus		: '',
			applicationPosition		: '',
			applicationAltPosition	: '',
			vesselPreferred			: [],
			
			rankOption 				: [],
			statusOption			: [],
			vesselTypeOption		: [],
			
			loadingData				: false,
			isLoaded 				: false,
			showDocumentLoading		: '',
			loadingApplication		: '',
			
			application				: [{ caption: '' }],
			employment				: [{ caption: '' }],
			schedule				: [{ caption: '' }],
			documents				: '',
			documentSummary: [
				{ key: 'valid',		title: 'Valid',			color: 'success',	textColor: 'success--text',	count: 0,	icon: 'mdi-file-check'	},
				{ key: 'renewal',	title: 'For Renewal',	color: 'warning',	textColor: 'warning--text',	count: 0,	icon: 'mdi-file-alert'	},
				{ key: 'expired',	title: 'Expired',		color: 'error',		textColor: 'error--text',	count: 0,	icon: 'mdi-file-cancel'	},
			],
			rulesStatus: [
				v => !!v || 'Status is required',
			],
			rulesPosition: [
				v => !!v || 'Position is required',
			],
			rulesAltPosition: [
				v => !!v || 'Alternate Position is required',
			],
			rulesVesselType: [
				v => !!v.length || 'Preferred Vessel Type is required',
			],
			vTourSteps: [
				{
					target: '#v-dashboard-step-1',
					content: 'Update your application details'
				},
			],
			vTourOptions: {
				startTimeout: 500,
				labels: {
					buttonStop: 'Update'
				}
			},
			vTourCallbacks:{
				onFinish: this.openApplication
			},
		}
	},
	methods: {
		openApplication(){
			this.dialogApplication = true;
		},
		startGuide(){
			if( this.applicationStatus == '' || this.applicationPosition == '' || this.applicationAltPosition == '' || this.vesselPreferred == '' ){
				if(!this.welcomeMessage){
					this.$tours['vTourDashboard'].start();
				}else{
					clearTimeout(timeOutTour);
					timeOutTour = setTimeout(() => {
						this.startGuide();
					}, 10000);
				}
			}
		},
		dashboardDetails () { 
			if(this.loadingData && typeof source !== 'undefined')source.cancel();
			
			const CancelToken = this.$http.CancelToken;
			let source = CancelToken.source();
			
			this.loadingData = true;
			this.isLoaded = false;
			
			clearTimeout(timeoutData);
				
			this.$http.get('dashboard', { cancelToken: source.token })
			.then((resp) => {
				
				this.documentSummary[0].count = resp.data.documentAlert.valid;
				this.documentSummary[1].count = resp.data.documentAlert.renewal;
				this.documentSummary[2].count = resp.data.documentAlert.expired;
				
				this.application	= resp.data.application;
				this.employment 	= resp.data.employment;
				this.schedule		= resp.data.schedule;
				
				this.applicationStatus		= resp.data.application[0].code;
				this.applicationPosition	= resp.data.application[1].code;
				this.applicationAltPosition	= resp.data.application[2].code;
				if(resp.data.application[3].value != '' && resp.data.application[3].value != null)this.vesselPreferred = resp.data.application[3].value.split(', ');
				
				const statusCode = resp.data.application[0].code;
				const statusDesc = resp.data.application[0].value;
				if(resp.data.application[0].caption == 'STATUS')this.statusOption.push( {'STATCODE': statusCode, 'STATDESC' : statusDesc} );
				
				this.isLoaded = true;
				
				this.startGuide();
			})
			.catch(() => {
				timeoutData = setTimeout(() => { this.dashboardDetails(); }, 60000);
			})
			.finally(() => {
				this.loadingData = false;
			});
			
			this.$http.get('dropdown/dashboardDropdowns').then((resp) => {
				this.rankOption = resp.data.rank;
				this.vesselTypeOption = resp.data.vesselType;
				this.statusOption.push( {'STATCODE': 'NA', 'STATDESC' : 'NEW APPLICANT'} );
			});
			
		},
		showDocuments (val, title){
			
			if(!this.isLoaded)return;
				
			this.dialogTitle = title;
			this.documents = '';
			this.showDocumentLoading = title;
			
			this.$http.get('dashboard/document', { params: { document: val } }).then((resp) => {
				this.documents = resp.data;
				this.dialogDocument = true;
			})
			.finally(() => {
				this.showDocumentLoading = '';
			});
		},
		saveApplication(){
			if(this.loadingApplication)return
			
			if(this.$refs.formApplication.validate()){
				this.loadingApplication = true;
				
				clearTimeout(timeoutSaveApplication);
				timeoutSaveApplication = setTimeout(() => {
					this.$http.put('dashboard/application', {
						status	: this.applicationStatus,
						rank	: this.applicationPosition,
						rankAlt	: this.applicationAltPosition,
						vessel	: this.vesselPreferred.join(', ')
					})
					.then(() => {
						this.dialogApplication = false;
						
						this.application[0].value = this.statusOption.find(({ STATCODE }) => STATCODE === this.applicationStatus).STATDESC;
						this.application[1].value = this.rankOption.find(({ RANKID }) => RANKID === this.applicationPosition).RANKDESC;
						this.application[2].value = this.rankOption.find(({ RANKID }) => RANKID === this.applicationAltPosition).RANKDESC;
						
						const rank = this.application[1].value;
						const name = this.fname;
						
						this.$store.dispatch(STORE_INFO, {rank, name} );
						
						if(this.vesselPreferred.length > 0){
							this.application[3].value = this.vesselPreferred.join(', ');
						}
						
						this.startGuide();
					})
					.finally(() => {
						clearTimeout(timeoutLoading);
						timeoutLoading = setTimeout(() => { this.loadingApplication = false; }, 200);
					});
				}, 50);
			}
		},
	},
	computed: {
		...mapGetters(['userFormHeight', 'errorOn', 'errorType', 'errorMessage', 'rank', 'fname', 'welcomeMessage']),
		applicationTitle () {
			return this.applicant ? 'APPLICATION SUMMARY' : 'EMPLOYMENT SUMMARY'
		},
		profileSummaryButton () {
			return this.profileSummary ? 'Hide Details' : 'View Details';
		},
		
	},
	created(){
		this.dashboardDetails();
	},
	beforeDestroy(){
		clearTimeout(timeOutTour);
		clearTimeout(timeoutData);
		clearTimeout(timeoutLoading);
		clearTimeout(timeoutSaveApplication);
	},
}
</script>

<style scoped>
	
	>>> .v-input__icon--append-outer .mdi-check-circle-outline{
		color: #4CAF50;
	}
	
</style>