<template>
	<Admin v-if="this.isAuthenticated && this.isAdmin" />
	<User v-else-if="this.isAuthenticated" />
	<Page v-else />
</template>

<script>

import { mapGetters } from 'vuex';

import User from './userPage/dashboard.vue';
import Page from './publicPage/Home.vue';
import Admin from './adminPage/dashboard.vue';

export default {
	name: 'ALSTERPH',
	components: {
		User,
		Page,
		Admin
	},
	computed:{
		...mapGetters(['isAuthenticated', 'isAdmin'])
	},
};
</script>